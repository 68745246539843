@font-face {
    font-family: 'Outfit';
    src: url('../assets/font/Outfit-Light.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Outfit';
    src: url('../assets/font/Outfit-Regular.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Outfit';
    src: url('../assets/font/Outfit-Medium.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Outfit';
    src: url('../assets/font/Outfit-Bold.ttf') format('truetype');
    font-weight: 700;
}