.standardButton {
    color: black;
    border-radius: var(--border-radius-full);
    padding: var(--spacing-sm) var(--spacing-md);
    gap: 10px;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: var(--text-md);
    justify-content: center;
}

.standardButton:disabled {
    background-color: lightgray !important;
}

.standardButtonPrimary {
    background-color: var(--col-primary);
}

.standardButtonSecondary {
    background-color: var(--col-secondary);
}