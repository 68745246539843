@import './variables.css';
@import './font.css';


/* import tailwind layers */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-weight: 500;
  font-family: Outfit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-col);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}